import React, { ReactElement } from 'react';

import { MdDashboard, MdAssignment, MdPieChart } from 'react-icons/md';
import { GoSettings } from 'react-icons/go';

import { useSiedeBar } from '../../hooks/sidebar';
import { useAuth } from '../../hooks/auth';

import ItemMenu from './ItemMenu';

import { groupBy } from '../../utils/groupBy';

import { Container, MainMenu } from './styles';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
}

interface Permission {
  id: string;
  category: string;
  name: string;
  report_id: string;
}

const SideBar: React.FC = () => {
  const { user, permissions } = useAuth();
  const { isOpened } = useSiedeBar();

  const menus: Menu[] = [];

  const newPermissions = groupBy(permissions, 'category');

  const permissionsKeys = Object.keys(newPermissions).sort();

  const userMenu = permissionsKeys.map((key) => {
    return {
      name: `${key}`,
      route: '#',
      icon: <MdPieChart size={24} color="#707070" />,
      children: newPermissions[`${key}`]
        ?.map((perm: Permission) => {
          return {
            name: perm.name,
            route: `/embedded/${perm.id}/${perm.report_id}`,
            icon: null,
            children: [],
          };
        })
        .sort((a: Permission, b: Permission) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
    };
  });

  const adminMenu = [
    {
      name: 'Dashboard',
      route: '/dashboard',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [],
    },
    {
      name: 'PowerBI',
      route: '#',
      icon: <MdAssignment size={24} color="#707070" />,
      children: [
        {
          name: 'Conta',
          route: '/account',
          icon: null,
          children: [],
        },
        {
          name: 'Categoria',
          route: '/categories',
          icon: null,
          children: [],
        },
        {
          name: 'Workspace',
          route: '/workspaces',
          icon: null,
          children: [],
        },
        {
          name: 'Relatorio',
          route: '/reports',
          icon: null,
          children: [],
        },
      ],
    },
    {
      name: 'Cadastros',
      route: '#',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        {
          name: 'Permissões',
          route: '/permissions',
          icon: null,
          children: [],
        },
        {
          name: 'Usuários',
          route: '/users',
          icon: null,
          children: [],
        },
      ],
    },
  ];

  if (user.role === 'admin') {
    adminMenu.map((adm) => menus.push(adm));
  }

  userMenu.map((usr) => menus.push(usr));

  return (
    <Container isMenuOpened={isOpened}>
      <MainMenu>
        {menus.map((menu: Menu) => (
          <ItemMenu key={menu.name} menu={menu} />
        ))}
      </MainMenu>
    </Container>
  );
};

export default SideBar;
