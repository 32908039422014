import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  role: string;
  rls: string;
}

interface Permission {
  id: string;
  name: string;
  category: string;
  report_id: string;
}
interface AuthState {
  token: string;
  user: User;
  permissions: Permission[];
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  permissions: Permission[];
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@a1Analytics:token');
    const user = localStorage.getItem('@a1Analytics:user');
    const permissions = localStorage.getItem('@a1Analytics:permissions');

    if (token && user && permissions) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        permissions: JSON.parse(permissions),
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const res = await api.post('sessions', { email, password });

    const { token, user, permissions } = res.data;

    localStorage.setItem('@a1Analytics:token', token);
    localStorage.setItem('@a1Analytics:user', JSON.stringify(user));
    localStorage.setItem(
      '@a1Analytics:permissions',
      JSON.stringify(permissions || []),
    );

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user, permissions });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@a1Analytics:token');
    localStorage.removeItem('@a1Analytics:user');
    localStorage.removeItem('@a1Analytics:permissions');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        ...data,
        token: data.token,
        user,
        permissions: data.permissions,
      });
      localStorage.setItem('@a1Analytics:user', JSON.stringify(user));
    },
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        permissions: data.permissions,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
