import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Embedded from '../pages/Embedded';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/embedded/:company/:report" component={Embedded} exact />
    </Switch>
  );
};

export default Routes;
